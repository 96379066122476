import React from 'react'
import { Layout, Stack, Main } from '@layout'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

const PageTv = props => (
    <Layout {...props}>
        <Seo title='Live TV' />
        <Stack>
            <Main>
                <iframe src="https://videoelephant.tv/" width="100%"  className="cm-content-frame"></iframe>
            </Main>
        </Stack>
    </Layout>
)

export default PageTv
